import React, { useEffect, useState } from "react"

import { useCountUp } from "react-countup"

function computeAmount({ amount, secondsElapsed }) {
  return (amount * secondsElapsed).toFixed(2)
}

function computeSecondsElapsed(fromDate) {
  let from = new Date(fromDate)
  let startDate = from.getTime() / 1000
  let now = Date.now() / 1000
  return now - startDate
}

function getRandomInterval() {
  return Math.floor(Math.random() * 2000)
}

export default function CounterRebates() {
  const [seconds, setSeconds] = useState(
    computeSecondsElapsed("2019-03-01T00:00:00Z")
  )
  const [amount, setAmount] = useState(
    computeAmount({
      amount: 0.00973649691358025,
      secondsElapsed: seconds,
    })
  )

  const { countUp, update } = useCountUp({
    start: 0,
    end: amount,
    delay: 0,
    prefix: "$",
    decimals: 2,
    separator: ",",
  })

  useEffect(() => {
    const intervalTime = getRandomInterval()
    const timeOut = setTimeout(() => {
      setSeconds(computeSecondsElapsed("2019-03-01T00:00:00Z"))
      setAmount(
        computeAmount({
          amount: 0.00973649691358025,
          secondsElapsed: seconds,
        })
      )
      update(amount)
    }, intervalTime)

    return () => clearTimeout(timeOut)
  }, [seconds, amount, update])

  return <span>{countUp}</span>
}
