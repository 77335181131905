import React from "react"
import { Link as GatsbyLink } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import styles from "./banner.module.css"

export default function Banner({ url, image, title }) {
  let renderBanner
  if (url.includes("https://")) {
    renderBanner = (
      <a
        href={url}
        className={styles.bannerHome}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img
          fluid={image}
          alt={`Puma FastPay ${title}`}
          className={styles.bannerHomeImg}
        />
      </a>
    )
  } else {
    renderBanner = (
      <GatsbyLink to={url} className={styles.bannerHome}>
        <Img
          fluid={image}
          alt={`Puma FastPay ${title}`}
          className={styles.bannerHomeImg}
        />
      </GatsbyLink>
    )
  }
  return renderBanner
}
