import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import AppStoreBtn from "../../images/app-store.svg"
import GooglePlayBtn from "../../images/google-play.svg"

import { itunesLink, googlePlayLink } from "../../constants/links"

import styles from "./hero-home-content.module.css"

export default function HeroHomeContent({
  phoneImage,
  title,
  text,
  downloadTitle,
}) {
  return (
    <div className={styles.heroContent}>
      <Img
        fluid={phoneImage}
        objectFit="cover"
        objectPosition="50% 50%"
        alt="Puma FastPay App Mobile"
        className={styles.heroContentImg}
      />
      <div className={styles.heroContentIntro}>
        <div className={styles.heroContentInfo}>
          <h2 id="hero-title" className={styles.heroContentInfoTitle}>
            {title}
          </h2>
          <h3 id="hero-text" className={styles.heroContentInfoText}>
            {text}
          </h3>
        </div>
        <div className={styles.heroContentDownload}>
          <h3 id="hero-download" className={styles.heroContentDownloadTitle}>
            {downloadTitle}
          </h3>
          <nav className={styles.heroContentDownloadButtons}>
            <a
              href={itunesLink}
              className={`${styles.heroContentDownloadButtonsButton} ${styles.heroContentDownloadButtonsButtonApple}`}
            >
              <img
                src={AppStoreBtn}
                alt="Puma FastPay App Store"
                className={styles.heroContentDownloadButtonsButtonImg}
              />
            </a>
            <a
              href={googlePlayLink}
              className={`${styles.heroContentDownloadButtonsButton} ${styles.heroContentDownloadButtonsButtonGoogle}`}
            >
              <img
                src={GooglePlayBtn}
                alt="Puma FastPay Google Play"
                className={styles.heroContentDownloadButtonsButtonImg}
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
