import React, { useState } from "react"
import ReactPlayer from "react-player"

import styles from "./video-player.module.css"

import Title from "../title/title"

export default function VideoPlayer({ defaultVideo, playlist }) {
  const [video, setVideo] = useState(defaultVideo)

  return (
    <div>
      <Title id="player__title" classVariant="titleVideoPlayer">
        {video.title}
      </Title>
      <div className={styles.videoPlayer}>
        <div className={styles.videoPlayerCurrentVideo}>
          <div className={styles.videoPlayerCurrentVideoIframe}>
            <ReactPlayer
              url={video.url}
              playing={video.autoplay}
              controls={true}
              playsinline={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className={styles.videoPlayerPlaylist}>
          {playlist.map((item, index) => {
            index++
            return (
              <div
                key={index}
                role="button"
                tabIndex="0"
                id={`player-video-${index}`}
                onClick={() => setVideo(item)}
                onKeyDown={() => setVideo(item)}
                className={`${styles.videoPlayerPlaylistVideo} ${
                  item.url === video.url
                    ? styles.videoPlayerPlaylistVideoActive
                    : ""
                }`}
              >
                <figure className={styles.videoPlayerPlaylistVideoCover}>
                  <img
                    src={item.thumbnail}
                    alt={`Puma FastPay ${item.title}`}
                    className={styles.videoPlayerPlaylistVideoCoverImg}
                  />
                  <span className={styles.videoPlayerVideoIconPlay}></span>
                </figure>
                <h3 className={styles.videoPlayerPlaylistVideoTitle}>
                  {item.title}
                </h3>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
