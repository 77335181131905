import React from "react"

import starsReview from "../../images/stars.svg"

import styles from "./list-reviews-item.module.css"

export default function ListReviewsItem({ username, title, text }) {
  return (
    <div className={styles.listReviewsItem}>
      <img
        src={starsReview}
        alt={`Puma FastPay ${title}`}
        className={styles.listReviewsItemStars}
      />
      <h3 className={styles.listReviewsItemUser}>{username}</h3>
      <p className={styles.listReviewsItemText}>
        <strong>{title}</strong>
        {text}
      </p>
    </div>
  )
}
