import React from "react"

import styles from "./feature-info.module.css"

import CounterRebates from "../counter-rebates/counter-rebates"

export default function FeatureInfo({
  children,
  classVariant,
  img,
  paymentIcons,
}) {
  return (
    <div
      className={`${styles.featuresInfo} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      <img src={img} alt="Puma FastPay" className={styles.featuresInfoImg} />
      {paymentIcons ? (
        <p id="payment-methods-text" className={styles.featuresInfoText}>
          {children}
        </p>
      ) : (
        <p id="rebates-text" className={styles.featuresInfoText}>
          {children}
        </p>
      )}
      <span className={styles.featuresInfoVl}></span>
      {paymentIcons ? (
        <img
          src={paymentIcons}
          alt="Puma FastPay payments"
          className={styles.featuresInfoPaymentMethods}
        />
      ) : (
        <h2 id="counter-rebates" className={styles.featuresInfoNumber}>
          <CounterRebates />
        </h2>
      )}
    </div>
  )
}
