import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./contact.module.css"

export default function Contact({ buttonText, image, faq, text }) {
  const { i18n } = useTranslation()

  const helpUrl = i18n.language === "en" ? "/help/" : "/ayuda/"

  return (
    <div id="home-contact" className={styles.homeContact}>
      <img
        src={image}
        alt={`Puma FastPay ${faq}`}
        className={styles.homeContactImg}
      />
      <h3 className={styles.homeContactTitle}>
        {faq}
        <br />
        {text}
      </h3>
      <a href={helpUrl} className={styles.homeContactButton}>
        {buttonText}
      </a>
    </div>
  )
}
