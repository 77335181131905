import React from "react"
import BackgroundImage from "gatsby-background-image"

import styles from "./hero-home-cover.module.css"

export default function HeroHomeCover({ background }) {
  return (
    <BackgroundImage
      className={styles.heroCover}
      Tag="div"
      fluid={background}
      backgroundColor={`#de1929`}
    ></BackgroundImage>
  )
}
